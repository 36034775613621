import React from "react"
import { Router } from "@reach/router"
// import LandingPage from "../components/LandingPage/landingPage"
import LandingPage from "./landingpage"
import HeatRecovery from "./heat-recovery"
import DistrictEnergy from "./district-energy"
import PageTransition from "gatsby-plugin-page-transitions"
import "../assets/css/main.scss"

import ReactGA from 'react-ga';
ReactGA.initialize('UA-178508374-1');

const IndexPage = () => (
  <PageTransition>
    <Router>
      <LandingPage path="/" />
      <DistrictEnergy path="/district-energy" />
      <HeatRecovery path="/heat-recovery" />
    </Router>
  </PageTransition>
)

export default IndexPage
